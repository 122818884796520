import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },{
    path: '/reg',
    name: 'reg',
    component: () => import('../views/reg.vue')
  },{
    path: '/',
    name: 'hgv',
    component: () => import('../views/hgv.vue')
  }, {
    path: '/person',
    name: 'person',
    component: () => import('../views/person.vue')
  }, {
    path: '/applicationCom',
    name: 'applicationCom',
    component: () => import('../views/applicationCom.vue')
  }, {
    path: '/applicationP',
    name: 'applicationP',
    component: () => import('../views/applicationP.vue')
  }, {
    path: '/success',
    name: 'success',
    component: () => import('../components/success.vue')
  }, {
    path: '/applycom',
    name: 'applycom',
    component: () => import('../views/applycom.vue')
  }, {
    path: '/product',
    name: 'product',
    component: () => import('../views/product.vue')
  }, {
    path: '/applycomwait',
    name: 'applycomwait',
    component: () => import('../views/applycomwait.vue')
  }, {
    path: '/applyP',
    name: 'applyP',
    component: () => import('../views/applyP.vue')
  }, {
    path: '/creaditCard',
    name: 'creaditCard',
    component: () => import('../views/creaditCard.vue')
  }, {
    path: '/getcode',
    name: 'getcode',
    component: () => import('../views/getcode.vue')
  }, {
    path: '/personal',
    name: 'personal',
    component: () => import('../views/personal.vue')
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
