<template>
  <div class="home">
    <div class="content">
      <div class="login_head">
        <h2>登录</h2>
      </div>
      <van-cell-group inset>
        <van-field v-model="mobile" type="tel" label="手机号" placeholder="请输入手机号" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field
          v-model="code"
          center
          clearable
          label="验证码"
          placeholder="请输入短信验证码"
          maxlength="6"
        >
          <template #button>
          
			<van-button size="small" @click="sendSms()" v-show="sending" type="primary" color="#428FFC"
				plain>发送验证码</van-button>
			
			<van-button size="small" v-show="!sending" type="primary" color="#428FFC" plain>
				已发送({{countdown}})</van-button>
          </template>
        </van-field>
      </van-cell-group>
      <van-button type="primary"  @click="login()" block color="#428FFC" class="submit" >立即登录</van-button>
      <van-button type="primary" block color="#E3E3E3" class="reg" @click="toReg(type)">注册</van-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
	import {
		Dialog,
		Toast
	} from 'vant'
export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return{
      code: '',
      mobile: '',
	  sending: true,
	  countdown: 60,
	  type:''
    }
  },
  mounted() {
  	this.type = this.$route.query.type
	if(!this.type){
		this.$router.push('/')
	}
  },
  methods:{
    toReg(type){
      this.$router.push({path:'reg',query:{type}})
    },
    toHgv(){
      this.$router.push('hgv')
    },
	login() {
		var that = this
		if(!this.type){
			this.$router.push('/');
			return false;
		}
		if(this.type!=1&&this.type!=2){
			this.$router.push('/');
			return false;
		}
		if (!this.mobile || !this.isPhone(this.mobile)) {
			Toast('手机号码未输入或格式错误')
			return false;
		}
		if (!this.code ) {
			Toast('请输入短信验证码')
			return false;
		}
		var params = new URLSearchParams();
		params.append('mobile', that.mobile);
		params.append('type', that.type);
		params.append('code', that.code);
		axios.post(that.GLOBAL.BASE_URL + "/api/login", params)
			.then(function(res) {
				if (res.data.status == 200) {
					Dialog.alert({
					  message: '登录成功',
					}).then(() => {
						that.$cookies.set('access_token', res.data.token)
						that.$cookies.set('type', res.data.type)
						that.$cookies.set('token_expire', res.data.token_expire)
						that.$router.push("/person");
					});
				} else {
					
					Dialog({
						message: res.data.msg
					})
				}
			});
	},
	sendSms() {
		if (!this.mobile || !this.isPhone(this.mobile)) {
			Toast('手机号码未输入或格式错误')
			return false;
		}
		var that = this
		var params = new URLSearchParams();
		params.append('mobile', that.mobile);
		axios.post(that.GLOBAL.BASE_URL + "/api/sendMobile", params)
			.then(function(res) {
				if (res.data.status == 200) {
					that.settime();
				} else {
					Toast(res.data.msg)	
				}
			});
	},
	isPhone(poneInput) {
		var myreg = /^[1][1,2,3,4,5,7,8,6,9][0-9]{9}$/;
		if (!myreg.test(poneInput)) {
			return false;
		} else {
			return true;
		}
	},
	settime() {
		if (this.countdown == 0) {
			this.sending = true;
			this.countdown = 60;
			return false;
		} else {
			this.sending = false;
			this.countdown--;
		}
		var that = this
		setTimeout(function() {
			that.settime();
		}, 1000);
	},
  },
  
}
</script>

<style scoped>
  .home{
    width: 100%;
    /* height: 100vh; */
    background-image: url('../assets/image/banner.png');
    background-repeat: no-repeat;
    background-size: 100%;
    /* overflow: hidden; */
    display: inline-table;
  }
  .home >>> .content{
    width: 100%;
    /* height: 95vh; */
    background-color: #fff;
    margin-top: 30%;
    border-radius: 16px 16px 0px 0px;
    /* overflow: hidden; */
    display: inline-table;
  }
  .home >>> .content .login_head{
    margin-top: 8.2%;
  }
  .home >>> .content .van-cell{
    flex-wrap: wrap;
  }
  .home >>> .content .van-cell .van-cell__title{
    width: 105%;
    color: #4a4a4a;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: .1em;
    margin: 5% 0 3%;
  }
  .home >>> .content .van-cell .van-cell__value{
    height: 48px;
    line-height: 48px;
    background-color: #f9f9f9;
    color: #999;
  }
  .home >>> .content .van-cell .van-cell__value .van-field__control{
    padding-left: 5%;
  }
  .home >>> .content .submit{
    width: 90%;
    margin: 10% auto;
    border-radius: 8px;
    position: relative;
    bottom: -15%;
  }
  .home >>> .content .reg{
    width: 90%;
    margin: 0 auto;
    border-radius: 8px;
    position: relative;
    bottom: -20%;
  }
  .home >>> .content .reg .van-button__text{
    color: #4a4a4a;
  }
</style>
