import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
import '../src/untils/rem'
import VueCookies from 'vue-cookies'
import global_ from './global'
Vue.prototype.GLOBAL = global_;

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Vant)
Vue.use(VueCookies);

router.beforeEach(async (to, from, next) => {
  if (!VueCookies.get('access_token')) {
		if(to.path == '/'||to.path == '/reg'||to.path == '/login'){
			next();
			return false;
		}else{
			next('/');
			return false;
		}
  }else{
		if (to.path == '/'||to.path == '/reg'||to.path == '/login') {
			var params = new URLSearchParams();
			var that=this;
			var access_token=VueCookies.get('access_token');
			params.append('token', access_token);
			axios.post(Vue.prototype.GLOBAL.BASE_URL + "/api/getUser", params)
				.then(function(res) {
				if (res.data.status == 1) {
					VueCookies.set('type',res.data.type)
					next('/person');
					return false;
				} else {
					next();
					return false;		
				}
			});
		}
		next();
		return false;		
  } 
})




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
